
<template>
    <div>
        <div class="org-panel">
            <div class="company-name">{{companyInfo.name || '暂无公司，请添加'}}</div>
            <div class="pull-right">
                <template v-if="companyInfo.id">
<!--         TODO: 暂时隐藏，日后会恢复（此时隐藏都是因为一个沙雕删错园区导致）           <button class="btn btn-primary" @click="deleteOrg(companyInfo.id)">删除</button>-->
                    <button class="btn btn-primary" @click="openEditOrg(companyInfo)">修改</button>
                </template>
                <button v-else class="btn btn-primary" @click="openAddOrg(ORG_LEVEL.COMPANY)">
                    <svg
                        class="icon"
                        aria-hidden="true"
                    >
                        <use xlink:href="#icon-menujiahao"></use>
                    </svg>
                    添加公司
                </button>
            </div>
        </div>

        <div class="result-panel">
            <CSTable>
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button class="btn btn-primary sticky-right" @click="openAddOrg(ORG_LEVEL.DEPARTMENT, companyInfo.code)">
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            添加部门
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>部门名称</th>
                        <th>组织级别</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="departmentList && departmentList.length > 0">
                        <template v-for="department in departmentList">
                            <tr :key="department.id">
                                <td>
                                    <span v-if="department.dutyType == DEPARTMENT_TYPES.INVESTMENT_PROMOTION && department.groups.length > 0"
                                          class="allow-click" @click="changeDepartmentUnfold(department.id)">
                                        {{department.name}}
                                        <svg :class="['icon', {reversal: departmentUnfold[department.id]}]" aria-hidden="true">
                                            <use xlink:href="#icon-menuxiala"></use>
                                        </svg>
                                    </span>
                                    <template v-else>
                                        {{department.name}}
                                    </template>
                                </td>
                                <td>部门</td>
                                <td>
                                    <div class="btn-group">
                                        <button
                                            type="button"
                                            class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            操作
                                        </button>
                                        <ul
                                            class="dropdown-menu"
                                        >
                                            <li
                                                @click="openEditOrg(department, ORG_LEVEL.DEPARTMENT)"
                                            >
                                                <a
                                                    style="width: 100%;"
                                                >修改</a>
                                            </li>
                                            <!--  TODO: 暂时隐藏，日后会恢复（此时隐藏都是因为一个沙雕删错园区导致）
                                                                                        <li @click="deleteOrg(department.id)">
                                                                                            <a
                                                                                                style="width: 100%;"
                                                                                            >删除</a>
                                                                                        </li>-->
                                            <li v-if="department.dutyType == DEPARTMENT_TYPES.INVESTMENT_PROMOTION" @click="openAddOrg(ORG_LEVEL.GROUP, department.code, department.dutyType)">
                                                <a
                                                    style="width: 100%;"
                                                >添加小组</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr :key="department.id + '_'" v-if="department.groups.length > 0 && departmentUnfold[department.id]">
                                <td colspan="3" style="padding: 0;">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th style="border-top: 0;">小组名称</th>
                                            <th style="border-top: 0;">组织级别</th>
                                            <th style="border-top: 0;">操作</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="group in department.groups"
                                            :key="group.id"
                                        >
                                            <td>{{group.name}}</td>
                                            <td>小组</td>
                                            <td>
                                                <div class="btn-group">
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        操作
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li
                                                            @click="openEditOrg(group, ORG_LEVEL.GROUP)"
                                                        >
                                                            <a
                                                                style="width: 100%;"
                                                            >修改小组</a>
                                                        </li>
                                                        <!--                                                        <li @click="deleteOrg(group.id)">
                                                                                                                    <a
                                                                                                                        style="width: 100%;"
                                                                                                                    >删除小组</a>
                                                                                                                </li>-->
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </template>
                    </template>
                </template>
            </CSTable>
            <Pagination
                name="Pagination"
                component="Pagination"
            ></Pagination>
        </div>

        <CSDialog :dialog-visible="orgDialog.visible" @onConfirm="orgDialog.onConfirm"
            :dialog-title="orgDialog.dialogInfo.title" :dialog-width="674" @onClose="orgDialog.onClose">
            <template v-slot:dialog-content>
                <div class="org-dialog-content">
                    <div class="dialog-content-item">
                        <div class="dialog-content-item-label">
                            {{orgDialog.dialogInfo.labelName}}
                        </div>
                        <div>
                            <input type="text" :maxlength="orgDialog.dialogInfo.inputMaxLength"
                                v-model="orgDialog.data.name"
                                :placeholder="`限${orgDialog.dialogInfo.inputMaxLength}个字`"
                                style="height: 40px;"
                            />
                        </div>
                    </div>
                    <div class="dialog-content-item" v-if="orgDialog.dialogInfo.orgLevel == ORG_LEVEL.DEPARTMENT">
                        <div class="dialog-content-item-label">
                            选择部门
                        </div>
                        <div>
                            <CSSelect height="40px" i-width="36px">
                                <select v-model="orgDialog.data.dutyType" style="padding-left: 6px;">
                                    <option value="">请选择</option>
                                    <option v-for="type in departmentTypes"
                                        :key="type.id" :value="type.id">
                                        {{type.name}}
                                    </option>
                                </select>
                            </CSSelect>
                        </div>
                    </div>
                    <div class="dialog-content-item" v-if="ticketDutyType.indexOf(+orgDialog.data.dutyType) > -1">
                        <div class="dialog-content-item-label">
                            工单接单时间
                        </div>
                        <div>
                            <CSSelect
                                    style="width: 185px; border-radius: 4px;"
                            >
                                <el-time-select
                                        v-model="ticketStartTime"
                                        format="HH:mm"
                                        value-format="HH:mm"
                                        placeholder="请选择起始时间"
                                        :picker-options="{
                                        start: '00:00',
                                        step: '00:30',
                                        end: '23:59'
                                      }"
                                >
                                </el-time-select>
                            </CSSelect>
                            <span class="inline-gray"></span>
                            <CSSelect
                                    style="width: 185px; margin-right: 20px; border-radius: 4px;"
                            >
                                <el-time-select
                                        v-model="ticketEndTime"
                                        format="HH:mm"
                                        value-format="HH:mm"
                                        placeholder="请选择起始时间"
                                        :picker-options="{
                                        start: '00:00',
                                        step: '00:30',
                                        end: '23:59'
                                      }"
                                >
                                </el-time-select>
                            </CSSelect>
                        </div>
                        <div style="margin-left: 1px">
                            <svg
                                    class="icon"
                                    aria-hidden="true"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            接单时间外的新工单将进入待分配状态。
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>


<script>
import Pagination from "@/components/Pagination.vue";
import {
    queryDepartmentUrl,
    deleteDepartmentUrl,
    editDepartmentUrl,
    createDepartmentUrl,
    queryTicketConfigUrl, queryOrderTimeUrl
} from "@/requestUrl";
import {STORAGE_KEY, ORG_LEVEL, DEPARTMENT_TYPES} from "@/constant/index.js";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import CSTable from "@/components/common/CSTable";


const orgDialogTexts = {
    [ORG_LEVEL.COMPANY]: {
        title: '添加公司',
        labelName: '公司名称',
        inputMaxLength: 30,
    },
    [ORG_LEVEL.DEPARTMENT]: {
        title: '添加部门',
        labelName: '部门名称',
        inputMaxLength: 10,
    },
    [ORG_LEVEL.GROUP]: {
        title: '添加小组',
        labelName: '小组名称',
        inputMaxLength: 10,
    },
}

export default {
    props: {},
    components: {
        CSTable,
        CSSelect,
        Pagination,
        CSDialog,
    },
    data() {
        return {
            ORG_LEVEL,
            DEPARTMENT_TYPES,
            userInfo: JSON.parse(localStorage.getItem(STORAGE_KEY.USER_INFO)),
            companyInfo: {},
            orgDialog: {
                visible: false,
                dialogInfo: {
                    orgLevel: ORG_LEVEL.DEPARTMENT,
                    ...orgDialogTexts[ORG_LEVEL.DEPARTMENT]
                },
                data: {
                    name: '',
                    dutyType: '',
                    parentCode: '',
                },
                onConfirm() {},
                onClose: () => {
                    this.orgDialog.visible = false;
                    this.ticketStartTime=null;
                    this.ticketEndTime=null;
                }
            },
            departmentList: [],
            departmentTypes: [
                { name: "总监办公室", id: DEPARTMENT_TYPES.DIRECTOR },
                { name: "经理办公室", id: DEPARTMENT_TYPES.MANAGER },
                { name: "工程部", id: DEPARTMENT_TYPES.ENGINEERING },
                { name: "客服部", id: DEPARTMENT_TYPES.CUSTOMER_SERVICE },
                { name: "保洁部", id: DEPARTMENT_TYPES.PROCTER_GAMBLE },
                { name: "保安部", id: DEPARTMENT_TYPES.SECURITY },
                { name: "人事部", id: DEPARTMENT_TYPES.PERSONNEL },
                { name: "财务部", id: DEPARTMENT_TYPES.FINANCIAL },
                { name: "运营部", id: DEPARTMENT_TYPES.OPERATIONS },
                { name: "招商部", id: DEPARTMENT_TYPES.INVESTMENT_PROMOTION },
                { name: "品质管理部", id: DEPARTMENT_TYPES.QUALITY },
                { name: "总裁办", id: DEPARTMENT_TYPES.PRESIDENT_OFFICE},
                { name: "行政部", id: DEPARTMENT_TYPES.ADMINISTRATION_DEPARTMENT},
                {name:'园区运营部',id:DEPARTMENT_TYPES.PARK_OPERATION_DEPARTMENT}
            ],
            departmentUnfold: {},
            ticketDutyType:[
                DEPARTMENT_TYPES.ENGINEERING,
                DEPARTMENT_TYPES.CUSTOMER_SERVICE,
                DEPARTMENT_TYPES.PROCTER_GAMBLE,
                DEPARTMENT_TYPES.SECURITY
            ],
            ticketStartTime:null,
            ticketEndTime:null,
            ticketOrderTime:{}
        };
    },
    async mounted() {
        await this.queryDepartmentList();
        await this.queryDepartmentList(ORG_LEVEL.DEPARTMENT, this.companyInfo.code);
        this.$vc.on(this.$route.path, "pagination_page", "event", _currentPage => {
            this.queryDepartmentList(ORG_LEVEL.DEPARTMENT, this.companyInfo.code, _currentPage);
        })
    },
    methods: {
         async queryOrderTime(dutyType,regionCode){
           await this.$fly.get(queryOrderTimeUrl,{
               regionCode,
               dutyType
           }).then(res => {
                if (res.data){
                    this.ticketStartTime = res.data.startTime;
                    this.ticketEndTime = res.data.endTime;
                }
           });

        },
        /**
         * 改变部门的展开状态
         * @param {Number} departmentId
         * */
        changeDepartmentUnfold(departmentId) {
            this.$set(this.departmentUnfold, departmentId, !this.departmentUnfold[departmentId]);
        },
        /**
         * 打开添加弹窗
         * @param {Number} orgLevel 组织等级  详见：常量ORG_LEVEL
         * @param {String | Number} parentCode 父级的code
         * @param {String | Number} dutyType 部门类型
         * */
        openAddOrg(orgLevel, parentCode, dutyType = '') {
            this.orgDialog.dialogInfo = {
                orgLevel,
                ...orgDialogTexts[orgLevel],
            }
            this.orgDialog.data = {
                name: '',
                dutyType,
                parentCode,
            }
            this.orgDialog.visible = true;
            this.orgDialog.onConfirm = () => {
                const {name, dutyType, parentCode} = this.orgDialog.data;
                this.$fly
                    .post(createDepartmentUrl, {
                        name,
                        dutyType,
                        parentCode,
                        regionCode: this.$vc.getCurrentRegion().code,
                    })
                    .then(res => {
                        if (res.code !== 0) {
                            return ;
                        }
                        this.$vc.toast('添加成功');
                        if (orgLevel != ORG_LEVEL.COMPANY) {
                            this.queryDepartmentList(ORG_LEVEL.DEPARTMENT, this.companyInfo.code);
                        } else {
                            this.queryDepartmentList(ORG_LEVEL.COMPANY);
                        }
                        this.orgDialog.visible = false;
                    });
            }
        },
        /**
         * 打开修改组织信息弹窗
         * @param {Object} org 组织信息
         * @param {Number} orgLevel 组织等级  详见：常量ORG_LEVEL
         * */
        openEditOrg(org, orgLevel) {
            const level = orgLevel === undefined ? org.level : orgLevel,
                {name, id, dutyType, parentCode, regionCode} = org;
            this.orgDialog.dialogInfo = {
                orgLevel: level,
                ...orgDialogTexts[level],
                title:  orgDialogTexts[level].title.replace('添加', '修改')
            }
            this.orgDialog.data = {
                name,
                dutyType,
                parentCode,
            }
            this.queryOrderTime(dutyType,regionCode);
            this.orgDialog.visible = true;

            this.orgDialog.onConfirm = () => {
                const {name, dutyType, parentCode} = this.orgDialog.data;
                this.$fly
                    .post(editDepartmentUrl, {
                        id,
                        name,
                        dutyType,
                        parentCode,
                        regionCode,
                        startTime:this.ticketStartTime,
                        endTime:this.ticketEndTime
                    })
                    .then(res => {
                        if (res.code !== 0) {
                            return ;
                        }
                        this.$vc.toast('修改成功');
                        if (level != ORG_LEVEL.COMPANY) {
                            this.queryDepartmentList(ORG_LEVEL.DEPARTMENT, this.companyInfo.code);
                        } else {
                            this.queryDepartmentList(ORG_LEVEL.COMPANY);
                        }
                        this.ticketStartTime=null;
                        this.ticketEndTime=null;
                        this.orgDialog.visible = false;
                    });
            }
        },
        /**
         * 删除组织
         * @param {Number} id 组织id
         * */
        deleteOrg(id) {
            this.$CSDialog.confirm({
                title: '提示',
                message: '确定删除吗？',
                onConfirm: () => {
                    this.$fly
                        .post(deleteDepartmentUrl, {id})
                        .then(res => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast("删除成功");
                            this.$CSDialog.instance.closeDialog();
                        });
                }
            })
        },
        /**
         * 查询公司/部门接口
         * @param {Number} level 0:代表公司
         * @param {String | Number} parentCode 父级id
         * @param {NUmber} pageNo 分页页数
         * @param {Number} pageSize 每次查看的数量
         *  */
        async queryDepartmentList(level = ORG_LEVEL.COMPANY, parentCode, pageNo = 1, pageSize = 100) {
            return this.$fly
                .post(queryDepartmentUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    code: "",
                    name: '',
                    dutyType: "",
                    parentCode,
                    level,
                    pageNo,
                    pageSize: level == ORG_LEVEL.COMPANY ? 100 : pageSize,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    const departmentList = [];
                    switch (+level) {
                        case ORG_LEVEL.COMPANY:
                            this.companyInfo = res.data[0] || {};
                            break;
                        case ORG_LEVEL.DEPARTMENT:
                            if (pageNo === 1) {
                                this.$vc.emit(this.$route.path, "pagination", "init", {
                                    currentPage: pageNo,
                                });
                            }
                            res.data.forEach(async (department) => {
                                department.groups = await this.queryDepartmentList(ORG_LEVEL.GROUP, department.code);
                                departmentList.push(department);
                            })
                            this.departmentList = departmentList;
                            break;
                    }
                    return res.data;
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.org-panel
    background #fff
    padding 24px 30px
    font-size 24px
    color #444
    div
        display inline-block
    .pull-right
        float right
        &::after
            content " "
            clear both
    .btn-primary
        height 30px
        padding 0 6px
        font-size 14px
        &:not(:last-of-type)
            margin-right 20px
.org-dialog-content
    padding 27px 30px
    .dialog-content-item
        font-size 24px
        &-label
            width 144px
            margin-right 40px
            text-align right
            color #444
        div
            display inline-block
            input
                border 1px solid #979797
                border-radius 4px
                padding 0 10px
                width 400px
                box-sizing border-box
            .cs-select
                display inline-flex
                select
                    width 388px
        &:not(:last-of-type)
            margin-bottom 23px
.table
    tr
        table
            width 100%
            background #F3F3F3
    .icon.reversal
        animation arrowRotate 0.1s linear 1
        transform rotate(180deg)
        tr
            border-bottom 2px solid #fff
@keyframes arrowRotate
    0%
        -webkit-transform rotate(0deg)
    50%
        -webkit-transform rotate(90deg)
    100%
        -webkit-transform rotate(180deg)
@keyframes arrowRotate
    0%
        -webkit-transform rotate(0deg)
    50%
        -webkit-transform rotate(90deg)
    100%
        -webkit-transform rotate(180deg)
</style>
